export const handleCursorClass = (className: string, type = 'add') => {
    if (type === 'add' || type === 'remove') {
        document.querySelector<any>('[data-element="cursor"]')?.classList[type](className);
    }
    // else if (type === 'remove-all') {
    //     document.querySelector('[data-element="cursor"]').classList.forEach(className => {
    //         className === 'Cursor_Cursor__gmuc7' && document.querySelector('[data-element="cursor"]').classList.remove(className);
    //     });
    // }
    return document.querySelector('[data-element="cursor"]');
}