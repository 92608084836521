import React, { ReactNode } from 'react'
import styles from './RoadmapBox.module.scss';
import { RightArrow2 } from "../../../../../Assets/SVG/svgImages";

type propTypes = {
    className?: string,
    heading?: string | ReactNode,
    year?: string | ReactNode,
    textList?: { text?: string | ReactNode }[],
}

const RoadmapBox = ({ className, heading, year, textList }: propTypes) => {
    return (
        <div className={`${styles.roadmap_box} ${className || ""}`}>
            <div className={styles.roadmap_box_title}>
                <h3>{heading}</h3>
                <span>{year}</span>
            </div>
            <ul>
                {
                    textList?.map((item, index) => {
                        return (
                            <li key={index}><RightArrow2 /> {item.text}</li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default RoadmapBox
