import React, { ReactNode } from 'react'
import styles from './ProfileCard.module.scss'
import { LinkedInIcon, Twitter } from '../../../Assets/SVG/svgImages'

type propTypes = {
    image?: string,
    name?: string | ReactNode,
    designation?: string | ReactNode,
    aboutText?: string | ReactNode,
    linkedInLink?: string,
}

const ProfileCard = ({ image, name, designation, aboutText, linkedInLink }: propTypes) => {
    return (
        <div className={styles.profile_team}>
            <div className={styles.advise_img}>
                <img src={image} alt="advise-img" />
                <div className={styles.linked_in}>
                    <a
                        href={linkedInLink}
                        target="_blank"
                        rel='noreferrer'
                    >
                        {/* <LinkedInIcon /> */}
                        <Twitter />
                    </a>
                </div>
            </div>
            <div className={styles.profile_content}>
                <h6>{name}</h6>
                <p>{designation}</p>
                <p className={styles.ambassadors_info}>{aboutText}</p>
            </div>
        </div>
    )
}

export default ProfileCard
