import React from 'react'
import Ambassadors from '../Ambassadors/Ambassadors'
import Advisors from '../Advisors/Advisors'
import Backers from '../Backers/Backers'
import Partners from '../Partners/Partners'
import Team from '../Team/Team'
import Roadmap from '../Roadmap/Roadmap'
import Banner from '../Banner/Banner'
import EcoSystem from '../EcoSystem/EcoSystem'
import SignupAccess from '../SignupAccess/SignupAccess'
import FeaturedIn from '../FeaturedIn/FeaturedIn'
import styles from "./Home.module.scss";
import Footer from '../../../Common/Footer/Footer'
import tokenImg from "../../../../Assets/Images/tokens-img.png";
import { Container } from 'react-bootstrap'
import pumpkin from '../../../../Assets/Images/pumpkin.png'

import CommonHeader from '../../../Common/CommonHeader/CommonHeader'

const Home = () => {
  return (
    <>
      <Banner />
      <EcoSystem />
      {/* <FeaturedIn /> */}
      <section className={styles.tokens} id="token">
        <Container>
          <div className={styles.box}>
            <CommonHeader
              title="TOKENOMICS"
              className={styles.heading}
            />
            <img src={tokenImg} className={styles.token_img} alt="" />
          </div>
          <img className={styles.pumpkin} src={pumpkin} alt="pumpkin" />
        </Container>
      </section>
      <Roadmap />
      <Backers />
      <Partners />
      <Team />
      {/* <Advisors />
      <Ambassadors /> */}
      <SignupAccess />
      <Footer />
    </>
  )
}

export default Home
