import React from 'react'
import styles from "./PlaySection.module.scss";
import { Col, Container, Row } from 'react-bootstrap';
import HeadingText from '../../../../Common/HeadingText/HeadingText';
import CommonCard from '../../../../Common/CommonCard/CommonCard';
import { ComputerIcon, IphoneIcon } from '../../../../../Assets/SVG/svgImages';
import Button from '../../../../Common/Button/Button';

const PlaySection = () => {
    return (
        <section className={styles.play_section}>
            <Container>
                <HeadingText
                    heading="PLAY"
                    className={styles.play_heading}
                />
                <Row>
                    <Col sm={6}>
                        <CommonCard className={styles.play_card}>
                            <div className={styles.play_card_icon}>
                                <span>
                                    <ComputerIcon />
                                </span>
                            </div>
                            <h3>FOR DESKTOP</h3>
                            <p>(Windows/OS)</p>
                            <Button
                                text="Coming Soon"
                                className={styles.action_btn}
                            />
                        </CommonCard>
                    </Col>
                    <Col sm={6}>
                        <CommonCard className={styles.play_card}>
                            <div className={styles.play_card_icon}>
                                <span>
                                    <IphoneIcon />
                                </span>
                            </div>
                            <h3>FOR MOBILE</h3>
                            <p>(iOS/Android)</p>
                            <Button
                                text="Coming Soon"
                                className={styles.action_btn}
                            />
                        </CommonCard>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PlaySection
