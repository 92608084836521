import React from 'react'
import styles from './Banner.module.scss'
import { Container } from 'react-bootstrap'
import LottieAnimation from '../../../Common/LottieAnimation/LottieAnimation'
import crows from '../../../../Assets/animations/crows-animation.json';
import pumpkin from '../../../../Assets/animations/pumpkin-animation.json';
import shibaCharacter from '../../../../Assets/Images/shiba-claws-character.png';
import Button from '../../../Common/Button/Button';

const Banner = () => {
    return (
        <section id="home" className={styles.banner}>
            <Container>
                <div className={styles.banner_inner}>
                    <div className={styles.banner_text}>
                        <h1>LET THE <br />GAME BEGIN</h1>
                        <p>First dynamic storyline web3 game  - Spooky Metaverse</p>
                        <div className='d-md-flex justify-content-lg-start justify-content-center align-items-center'>
                            <div className={styles.coming__soon}>
                                <Button
                                    text="Mint"
                                    className={styles.btn_mint}
                                    variant="bordered"
                                />
                                <span className={styles.coming_soon_text}>Coming Soon</span>
                            </div>
                            <Button
                                className={styles.btn_join}
                                text="Join"
                                onClick={() => window.open("https://spookyshiba.substack.com/embed", "_black", "noreferrer")}
                            />
                        </div>
                    </div>
                    <div className={styles.banner_img}>
                        <img src={shibaCharacter} alt="shiba-character" className={styles.shiba_char} />
                    </div>
                </div>
                <LottieAnimation
                    animation={crows}
                    speed={0.5}
                    ariaLabel={"crows"}
                />
                <LottieAnimation
                    speed={0.8}
                    animation={pumpkin}
                    ariaLabel={"pumpkin"}
                />
            </Container>
        </section>
    )
}

export default Banner
