import React from 'react';
import Lottie from 'react-lottie'

type propTypes = {
    animation?: {},
    ariaLabel?: string,
    speed?: number,
}

const LottieAnimation = ({ animation, ariaLabel, speed, ...rest }: propTypes) => {
    const options = {
        loop: true,
        autoplay: true,
        animationData: animation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Lottie
            {...rest}
            speed={speed}
            ariaLabel={ariaLabel}
            options={options}
        />
    )
}

export default LottieAnimation;