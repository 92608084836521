import React, { MouseEvent, ReactNode } from "react";
import styles from "./Button.module.scss";
import { handleCursorClass } from "../Cursor/CursorFunctions";

type propTypes = {
  variant?: "bordered" | "secondary-btn" | "secondary-bordered";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  text: string | ReactNode;
};

const Button = ({ variant, onClick, className, text, ...rest }: propTypes) => {
  const handleEnter = () => {
    handleCursorClass("active");
  };
  const handleLeave = () => {
    handleCursorClass("active", "remove");
  };
  return (
    <button
      {...rest}
      data-variant={variant}
      onClick={onClick}
      className={`${className || ""} ${styles.button}`}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
    >
      <span>{text}</span>
    </button>
  );
};

type SimpleButtonTypes = {
  variant?: "bordered-yellow" | "bordered-orange";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  text: string | ReactNode;
};
export const SimpleButton = ({
  variant,
  className,
  onClick,
  text,
  ...rest
}: SimpleButtonTypes) => {
  return (
    <button
      {...rest}
      data-variant={variant}
      onClick={onClick}
      className={`${className || ""} ${styles.simple_btn}`}
    >
      <span>{text}</span>
    </button>
  );
};

export default Button;
