import { combineReducers } from 'redux';
import { UserSlice } from '../Slices/user.slice'
import { LoaderSlice } from '../Slices/loader.slice'
import { IcoSlice } from '../Slices/ico.slice';
import { HomeLoader } from '../Slices/homeloader.slice';
/**COMBINE ALL REDUCERS */
export const reducers = combineReducers({
    user: UserSlice.reducer,
    loader: LoaderSlice.reducer,
    ico: IcoSlice.reducer,
    homeloading: HomeLoader.reducer,
});