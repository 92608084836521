import React from 'react'
import styles from '../Backers/Backers.module.scss'
import backers_img from '../../../../Assets/Images/partners/antier.png'
import backers_img2 from '../../../../Assets/Images/partners/lki.png'
import backers_img3 from '../../../../Assets/Images/partners/mirai.png'
import pumpkin from '../../../../Assets/Images/pumpkin.png'
import { Container } from 'react-bootstrap'
import CommonHeader from "../../../Common/CommonHeader/CommonHeader"
import Slider from "react-slick";


const Partners = () => {
    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section className={styles.Backers_sec}>
            <Container>
                <div className={styles.Backers_sec_box}>
                    <CommonHeader
                        title="Partners"
                    />
                    <div className={styles.Backers_sec_box_row}>
                        <Slider {...settings}>
                            <div className={styles.buy_col}>
                                <div className={styles.backers_img}>
                                    <img src={backers_img} className={styles.partner_img} alt="backer-img" />
                                </div>
                            </div>
                            <div className={styles.buy_col}>
                                <div className={styles.backers_img}>
                                    <img src={backers_img2} className={`${styles.partner_img} ${styles.lki}`} alt="backer-img" />
                                </div>
                            </div>
                            <div className={styles.buy_col}>
                                <div className={styles.backers_img}>
                                    <img src={backers_img3} className={styles.partner_img} alt="backer-img" />
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
                <div className={`Backers_sec_pumpkinImg ${styles.partners_pumpkin}`}>
                    <img src={pumpkin} alt="pumpkin" />
                </div>
            </Container>
        </section>
    )
}

export default Partners
