import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    walletAddress: "",
    walletType: "",
    userDetails: {},
    network: "matic",
    isTCAccepted:false
}

/**USER DETAILS SLICE */
export const UserSlice = createSlice({
    name: 'user',
    initialState,

    reducers: {
        userDetails: (state, param) => {
            const { payload } = param;
            state.userDetails = payload;
        },  
        TCacceptance: (state, param) => {
            const { payload } = param;
            state.isTCAccepted= payload;
        },

        walletAddress: (state, param) => {
            const { payload } = param;
            state.walletAddress = payload;
        },

        walletType: (state, param) => {
            const { payload } = param;
            state.walletType = payload;
        },

        logoutUser: (state) => {
            state.walletAddress = "";
            state.walletType = "";
        },
        reset: () => initialState,
    }
})

/**ACTIONS FOR SLICE*/
export const { userDetails, walletAddress, logoutUser, walletType,TCacceptance,reset } = UserSlice.actions

