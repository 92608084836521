import React from 'react'
import PlaySection from './PlaySection/PlaySection';
import styles from "./Game.module.scss";
import HeadingText from '../../../Common/HeadingText/HeadingText';
import { Col, Container, Row } from 'react-bootstrap';
import antiHero1 from "../../../../Assets/Images/anti-hero-1.png";
import antiHero2 from "../../../../Assets/Images/anti-hero-2.png";
import hero1 from "../../../../Assets/Images/hero-1.png";
import hero2 from "../../../../Assets/Images/hero-2.png";
import { Link } from 'react-router-dom';

const Game = () => {
    return (
        <>
            <PlaySection />
            <Container>
                <section className={styles.lore_section}>
                    <HeadingText
                        heading="LORE"
                        className={styles.heading}
                    />
                    <p>He wakes up in the middle of nowhere. Scared, cold and confused.</p>
                    <h3>Blurry vision, massive headache…</h3>
                    <p>He stands up, but a distant reflection of a monster spooks him away, he’s hiding.</p>
                    <p>To very soon realize - it is his own reflection.</p>
                    <h3>This can’t be, it’s just a dream…</h3>
                    <p>He pinches himself trying to wake up from this nightmare, tho instead of tiny little shiba nails, massive metal claws perforate his body.</p>
                    <p>Nothing will be like it ever was. There is no way back. It is reality.</p>
                </section>
                <section className={`${styles.game_section} ${styles.heroes}`}>
                    <HeadingText
                        heading="HEROES"
                        className={styles.heading}
                    />
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <div className={styles.game_box}>
                                    <img src={hero1} alt="" />
                                    <h3>Spooky Shiba - lead Hero</h3>
                                    <p>Genetically modified shiba, half dog half cyborg. Join the <br className="d-xxl-block d-none" /> Spooky verse and find out how did this happen. He doesn’t remember anything.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className={styles.game_box}>
                                    <img src={hero2} alt="" />
                                    <h3>Unicorn</h3>
                                    <p>She’s glamorous, but don’t let her charm you. Very powerful <br className="d-xxl-block d-none" />  creature, standing by Spooky Shiba’s side.</p>
                                </div>
                            </Col>
                        </Row>
                        <Link to="" className={styles.more_text}>MORE TO BE REVEALED</Link>
                    </Container>
                </section>
                <section className={`${styles.game_section} ${styles.antiheroes}`}>
                    <HeadingText
                        heading="ANTI-HEROES"
                        className={styles.heading}
                    />
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <div className={styles.game_box}>
                                    <img src={antiHero1} alt="" />
                                    <h3>Red Devil - lead Anti-Hero</h3>
                                    <p>He’s lives mission is to destroy all of the goodness in the World. Forever angry and looking for innocent creature’s blood to spill, <br className="d-xxl-block d-none" />  just for fun…</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className={styles.game_box}>
                                    <img src={antiHero2} alt="" />
                                    <h3>Fox</h3>
                                    <p>Dark knight, red-eyed warrior of evil. <br className="d-xxl-block d-none" /> Devil’s right hand and his closest fellow. He may not be as tall, <br className="d-xxl-block d-none" /> but very deadly.</p>
                                </div>
                            </Col>
                        </Row>
                        <Link to="" className={styles.more_text}>MORE TO BE REVEALED</Link>
                    </Container>
                </section>
            </Container>
        </>
    )
}

export default Game
