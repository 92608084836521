import { Provider } from "react-redux";
import store from "./Redux/Store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import Application from "./Application";
import { useEffect } from "react";
import Aos from "aos"
import Cursor from "./Components/Common/Cursor/Cursor";
// import { Toaster } from "react-hot-toast";
// import Loader from "./Components/Common/Loader/Loader";
// import { io } from "socket.io-client";

/**CREATE STORE PERSIST INSTANCE */
let persistor = persistStore(store);

// export const socket: any = io("Your Socket URL comes here", {
//   reconnection: true,
//   transports: ["websocket"],
// });

function App() {
  // console.log = () => {};
  // console.warn = () => { };
  // console.error = () => { };

  // console.log("build number", 4);



  useEffect(() => {
    Aos.init({});
    // AOS.refresh();
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* {ReactDOM.createPortal(<Toaster />, document.body)} */}
        {/* <Loader /> */}
        <Application />
        {/* <Cursor /> */}
      </PersistGate>
    </Provider>
  );
}

export default App;
