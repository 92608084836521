import React from 'react'
import { InputGroup, FormControl, Container } from 'react-bootstrap'
import styles from './SignupAccess.module.scss'
import loin_img from '../../../../Assets/Images/doge-effect-shiba.png'
import CommonHeader from '../../../Common/CommonHeader/CommonHeader'
import Button from '../../../Common/Button/Button'

const SignupAccess = () => {
    return (
        <section className={styles.Signup_Access}>
            <Container>
                <div className={styles.Signup_Access_box}>
                    {/* <h2>Signup for early game access</h2> */}
                    <CommonHeader
                        title="Signup for early game access"
                    />

                    <div className={styles.Signup_Access_box_InputArea}>
                        <InputGroup className={styles.signup_input}>
                            {/* <FormControl placeholder="Enter your email address" /> */}
                            <Button
                                text="Sign Up"
                                className={styles.signup_btn}
                                onClick={() => window.open("https://spookyshiba.substack.com/embed", "_black", "noreferrer")}
                            />
                        </InputGroup>
                    </div>
                </div>
            </Container>
            <div className={styles.shiba_loin}>
                <img src={loin_img} alt="loin-img" />
            </div>
        </section>
    )
}

export default SignupAccess
