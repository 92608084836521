import React, { useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styles from './Homelayout.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setLoading } from '../../../Redux/Slices/homeloader.slice'
import Loader from '../../Common/Loaders/Loader/Loader'
import Cursor from '../../Common/Cursor/Cursor';
import Header from '../../Common/Header/Header'

const Homelayout = () => {
    const location = useLocation();
    const [showHeader, setShowHeader] = useState(true);
    const loadingState = useSelector((state: any) => state.homeloading);
    const dispatch = useDispatch();


    let noHeaderRoutes = useMemo(() => [
        {
            id: 1,
            path: "/",
        }
    ], []);

    useEffect(() => {
        // setShowHeader(!(noHeaderRoutes.filter(
        //     item => location.pathname === item.path).length > 0
        // ));
    }, [location, noHeaderRoutes])

    useEffect(() => {
        dispatch(setLoading(true));
        setTimeout(() => {
            dispatch(setLoading(false));
        }, 3000);
    }, [dispatch])
    // return false ?
    return loadingState?.loading ?
        <Loader />
        :
        <>
            <main className={`${styles.main} ${!showHeader ? styles.no_header : ""}`}>
                {showHeader && <Header />}
                <Outlet />
            </main>
            {/* <Cursor /> */}
        </>
}

export default Homelayout
