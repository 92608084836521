import React, { useState } from 'react'
import styles from './EcoSystem.module.scss';
import { Container } from 'react-bootstrap';
// import videoImg from '../../../../Assets/Images/video-imag.png';
import videoImg from '../../../../Assets/Images/ecosystem-graphic.png';
import pumpkin from '../../../../Assets/Images/pumpkin2.png';
import CommonHeader from '../../../Common/CommonHeader/CommonHeader';
import { PauseIcon, RightArrow } from '../../../../Assets/SVG/svgImages';
import { Link } from 'react-router-dom';

const EcoSystem = () => {
    const [active, setActive] = useState(true);
    return (
        <section className={styles.ecosystem}>
            <img src={pumpkin} className={styles.pumpkin} alt="pumpkin" />
            <Container>
                <CommonHeader
                    title="SPOOKY ECOSYSTEM"
                    // info="Lorem Ipsum is simply dummy text simply dummy text of the printing and typesetting industry."
                    className={styles.ecosystem_header}
                />
                <div className={styles.ecosystem_video}>
                    <iframe name="iframe_video1" src="https://www.youtube.com/embed/alaUb1qAfQw?si=zpk8UmcD8_ov6sKa" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                    <span className="my-5 d-block"></span>
                    <img className='mt-5' src={videoImg} alt="video" />
                    {/* <button
                        onClick={() => setActive(!active)}
                        className={styles.play_btn}
                    >
                        {
                            active ? <span><RightArrow /></span> : <PauseIcon />
                        }
                    </button> */}
                </div>
            </Container>
        </section>
    )
}

export default EcoSystem
