import React from 'react';
import styles from './HeaerNav.module.scss';
import { Link } from 'react-scroll';
import { NavLink as Routerlink } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap';
import { DroparrowIcon } from '../../../../Assets/SVG/svgImages';

type propTypes = {
    data?: {
        label?: string,
        to: string,
        link?: boolean,
    }[],
    handleActive?: () => void,
    active?: boolean,
}

const HeaderNavigation = ({ data, handleActive, active }: propTypes) => {
    return (
        <>
            <div
                onClick={handleActive}
                className={`${styles.overlay} ${active ? styles.active : ""} d-lg-none`}
            />
            <ul className={`${styles.header_nav} ${active ? styles.active : ""}`}>
                <li>
                    <Link
                        to='/'
                        onClick={handleActive}
                        spy
                        offset={-40}
                        duration={500}
                    >
                        HOME
                    </Link>
                </li>
                <li>
                    <Link
                        to='team'
                        onClick={handleActive}
                        spy
                        offset={-40}
                        duration={500}
                    >
                        TEAM
                    </Link>
                </li>
                <li>
                    <Dropdown className='commondrop'>
                        <Dropdown.Toggle disabled id="dropdown-basic">
                            NFT <DroparrowIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Routerlink to='#' className='dropdown-item' onClick={handleActive}>Mint</Routerlink>
                            <Routerlink to='#' className='dropdown-item' onClick={handleActive}>buy/rent</Routerlink>
                        </Dropdown.Menu>
                    </Dropdown>
                </li>
                <li>
                    <Link
                        to='token'
                        onClick={handleActive}
                        spy
                        offset={-40}
                        duration={500}
                    >
                        TOKEN
                    </Link>
                    {/* <Dropdown className='commondrop'>
                        <Dropdown.Toggle disabled id="dropdown-basic">
                            TOKEN <DroparrowIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Routerlink to='#' className='dropdown-item' onClick={handleActive}>Staking</Routerlink>
                            <Routerlink to='#' className='dropdown-item' onClick={handleActive}>vesting</Routerlink>
                        </Dropdown.Menu>
                    </Dropdown> */}
                </li>
                <li>
                    <Routerlink
                        to='/auth/game'
                        onClick={handleActive}
                    >
                        GAME
                    </Routerlink>
                </li>
                <li>
                    <Link
                        data-disabled="true"
                        to='whitepaper'
                        onClick={handleActive}
                        spy
                        offset={-40}
                        duration={500}
                    >
                        WHITEPAPER
                    </Link>
                </li>
                <li>
                    {/* <Link
                        data-disabled="true"
                        to='contact'
                        onClick={handleActive}
                        spy
                        offset={-40}
                        duration={500}
                    >
                        CONTACT
                    </Link> */}
                    <Routerlink
                        to="mailto:chris@spookyshiba.com"
                    >
                        CONTACT
                    </Routerlink>
                </li>
            </ul>
        </>
    )
}

export default HeaderNavigation
