import React, { ReactNode } from 'react';
import styles from "./CommonCard.module.scss";

type propTypes = {
    heading?: string | ReactNode,
    className?: string,
    children?: ReactNode,
}

const CommonCard = ({ heading, className, children }: propTypes) => {
    return (
        <div className={`${styles.card} ${className || ""}`}>
            <div className={styles.card_inner}>
                {
                    heading &&
                    <div className={styles.card_header}>
                        <h3>{heading}</h3>
                    </div>
                }
                <div className={styles.card_body}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default CommonCard
